.multi-checkbox {
    border: 1px solid #dcdee2;
    border-radius: 5px;
    padding: 6px 16px 6px 16px;
    .checkbox-header {
        border-bottom: 1px solid #e9e9e9;
        padding-bottom:6px;
        margin-bottom:6px;
    }
    .ivu-checkbox-group {
        display: flex;
        flex-direction: column;
        min-height: 50px;
        max-height: 50vh;
        overflow-y: auto;
    }
}
