// Font Stuff
$ultraLight: 100;
$thin: 200;
$light: 300;
$normal: 400;
$semibold: 600;
$bold: 700;
$body: "proxima-nova",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$titles: "aktiv-grotesk-std",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$condensed: "proxima-nova-alt-condensed",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$white: #fff;
$black: #000;
$red: hsl(0, 100%, 50%);
$header-height: 55px;
$footer-height: 45px;
$html-width: 420px;
$xs-color: #a29bfe;
$sm-color: #1abc9c;
$md-color: #4F91FC;
$lg-color: #ff7675;
$morefaded: 0.3;
$lessfaded: 0.2;
.form-ui-builder {
    padding: 20px;
    background: #F5F6FC;
    display: flex;
    height: 100vh;
    .ivu-tabs-tabpane {
        border-left: solid 1px #dedede !important;
    }
    .moqup-list {
        height: calc(100vh - 80px);
        overflow-y: auto;
        width: 250px;
        .ivu-form-item {
            border: dashed 1px #cccccc;
            padding: 5px;
        }
        .controls-list {
            padding: 20px 10px 100px;
            border-left: solid 1px #dedede;
            height: 100%;
            width: 250px;
            overflow-y: auto;
        }
    }
    .pz-workspace {
        flex: 1;
        position: relative;
        border: solid 1px #e5e5e5 !important;
        margin: 10px;
        width: calc(100% - 180px);
        &-header {
            border-bottom: solid 1px #dedede;
            width: 100%;
            position: absolute;
            top: 0;
            height: 40px;
            background: #F5F6FC;
            &-wrapper {
                padding: 0 21px;
                margin: -1px;
                display: flex;
                align-items: center;
                height: 40px;
                background: #4586FC;
                .header-control {
                    a {
                        display: inline-flex;
                        height: 30px;
                        width: 30px;
                        align-items: center;
                        justify-content: center;
                        i {
                            font-size: 20px;
                            color: #ffffff;
                        }
                        &:hover {
                            background: darken(#4586FC, 10%);
                        }
                    }
                }
                .view-port-switcher {
                    margin-left: auto;
                    align-items: center;
                    justify-content: right;
                    a {
                        height: 30px;
                        width: 30px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        color: #e5e5e5;
                        border-radius: 2px;
                        border:none;
                        &.active {
                            color: #ffffff;
                            border: solid 1px #f5f5f5 !important;
                            background-color: $md-color;
                            border: solid 1px $md-color;
                        }
                    }
                }
            }
        }
        &-container {
            overflow-y: auto;
            margin-top: 40px;
            background: #ffffff !important;
            display: flex;
            margin-bottom: 40px;
            height: calc(100vh - 120px);
            overflow-y: auto;
            &-wrapper {
                position: relative;
                width: 100%;
                margin: 0 auto;
                .ivo-row {
                    width: 100%;
                    margin: 10px 0;
                    background: #ffffff;
                }
                .ivu-col {
                    margin: 0 !important;
                    padding-left: 8px !important;
                    padding-right: 8px !important;
                }
                .grid-schema-shadow {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 897px;
                    padding: 0;
                    margin: 0;
                    min-height: 300px;
                    z-index: 1;
                    display: none;
                    .col-shadow {
                        height: 100%;
                        width: 100%;
                        background: rgba(#888888, .1); // margin: 0 16px 0 0;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .grid-schema {
                    position: relative;
                    width: 100%;
                    z-index: 11;
                    padding: 20px 0 50px;
                    margin: 0;
                    //overflow: hidden;
                    overflow-y: auto;
                    .ivu-row {
                        padding-top: 35px;
                        padding-bottom: 20px;
                        margin-top: 10px;
                        margin-bottom: 20px; // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                        background: rgba(#fff, .6);
                        border: dashed 1px #dedede;
                        .pz-row-control {
                            position: absolute;
                            right: 10px;
                            top: 5px;
                            .tool {
                                color: rgba(#000000, .5);
                                font-size: 18px;
                                width: 24px;
                                height: 24px;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                background: #f3f4f5;
                                &:hover {
                                    color: #4586FC;
                                    cursor: pointer;
                                }
                            }
                        }
                        .pz-col {
                            background-color: #F4F3F4;
                            margin-bottom: 20px;
                            .ivu-row {
                                background: #ffffff !important;
                                border: dashed 1px #dedede;
                                margin-left: 10px;
                                margin-right: 10px;
                                .ivu-poptip-body {
                                    padding: 3px;
                                    height: 115px;
                                    width: 200px;
                                }
                                .auto-col {
                                    a {
                                        display: block;
                                        float: left;
                                        margin: 3px 3px 5px 3px;
                                        cursor: pointer;
                                        height: 20px;
                                        width: 40px; // overflow: hidden;
                                        // border: solid 1px #dedede;
                                        span {
                                            background: #e5e5e5;
                                            display: inline-flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 20px;
                                            border-right: solid 1px #cccccc;
                                            font-size: 8px;
                                            &:last-child {
                                                border-right: 0;
                                            }
                                        }
                                        &:hover {
                                            span {
                                                background: #dedede;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .ivu-col {
                            // height: 160px;
                            // border: solid 1px red;
                            height: auto;
                            .pz-col {
                                width: 100%;
                                position: relative;
                                overflow-y: hidden;
                                height: auto;
                                .pz-holder {
                                    background-color: #F4F3F4;
                                    padding: 10px;
                                    height: auto;
                                    min-height: 50px;
                                    width: 100%;
                                    position: relative;
                                    border: dotted 1px #dedede;
                                    padding: 10px;
                                    .pz-holder-in {
                                        background: #fff;
                                        display: block;
                                        height: 100%;
                                    }
                                }
                                .pz-col-control {
                                    width: 100%;
                                    border-bottom: solid 1px rgba(#ffffff, .3);
                                    padding: 5px 6px 3px 8px;
                                    display: flex;
                                    align-items: center;
                                    label {
                                        flex: 1;
                                        font-size: 13px;
                                        small {
                                            color: #f4f5f6;
                                            font-size: 13px;
                                        }
                                    }
                                    .pz-col-input {
                                        flex: 1;
                                        font-size: 13px;
                                        max-width: 150px;
                                    }
                                    .pz-col-control-items {
                                        margin-left: auto;
                                        text-align: right;
                                    }
                                    .ivu-select-multiple .ivu-select-item{
                                        text-align: left;
                                    }
                                    .ivu-badge{
                                        position: absolute;
                                        top: -5px;
                                        left: -25px;
                                        .badge-user-roles{
                                            background: #3863e9 !important;
                                        }
                                    }
                                    .tool {
                                        color: rgba(#000000, .5);
                                        font-size: 18px;
                                        display: inline-block;
                                        background: rgba(#000000, .12);
                                        width: 20px;
                                        height: 20px;
                                        display: inline-flex;
                                        align-items: center;
                                        justify-content: center;
                                        &:hover {
                                            color: #ffffff;
                                            cursor: pointer;
                                        }
                                    }
                                }
                                .resizer {
                                    width: 5px;
                                    height: 100%;
                                    background: transparent;
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    cursor: ew-resize;
                                    &:active,
                                    &:hover,
                                    &:focus {
                                        background: rgba(#666666, .6);
                                    }
                                }
                            }
                        }
                    }
                    .pz-new-row {
                        height: 80px;
                        background: rgba(#ffffff, .3);
                        margin: 20px auto;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-left: 0;
                        border: 1px dashed #cccccc;
                        border-radius: 2px;
                        cursor: pointer;
                        i {
                            font-size: 32px;
                        }
                        &:hover {
                            border: 1px dashed #4586FC;
                            background: rgba(#ffffff, .6);
                            i {
                                color: #4586FC;
                            }
                        }
                    }
                }
            }
        }
    }
}
