.formula-wrapper {
    width: 100%;

    .formula-form-wrapper{
        padding: 20px;
    }

    .formula-helper {
        padding: 5px;
        margin-top: 5px;
        line-height: 14px;
        color: #888888;
        font-style: italic;
    }
}
