.upload-wrapper {
    position: relative;
    > .upload-handler {
        padding: 20px;
    }
    .preview {
        border: dotted 1px #dedede;
        width: 100%;
        background: #f1f2f3;
        padding: 10px;
        .upload-handler {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(#ffffff, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition: opacity 0.3s;
            p {
                font-weight: bold;
                color: rgb(51, 153, 255);
            }
        }
        &:hover {
            .upload-handler {
                opacity: 1;
            }
        }
        img {
            max-height: 120px;
        }
    }
}
