.file-uploader {
    border: dashed 1px #0C78E6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    .file-upload-handler {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;
        i {
            font-size: 16px;
            margin-right: 5px;
        }

    }

    .file-control {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: dashed 1px #CCCCCC;
        a {
            margin: 0 10px;
            cursor: pointer;
            i {
                margin-right: 5px;
            }
        }
    }
}
