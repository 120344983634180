//@import "~leaflet-draw/dist/leaflet.draw.css";
.geographic{
  height: 450px;
  width: 100%;
  position: relative;
  overflow: hidden;
    #geographic{
        z-index: 1;
    }
  //border: 1px solid #666;
  #spatial_query{
    position: absolute;
    right: 0px;
    padding: 20px 10px;
    bottom: 0px;
    background: rgba(255,255,255,0.7);
    z-index: 2;
  }
  #side_bar {

    width: 400px;
    position: absolute;
    right: -400px;
    padding: 5px 15px 15px 15px;
    top: 10px;
    z-index: 2;
    height: auto;
    max-height: 430px;
    //overflow-y: auto;
    transition: all 0.5s ease;
    background: #fff;

    hr{
      margin: 15px 0;
    }
    .geometry_type{


    }
    .side-toggle{
      position: absolute;
      left: -60px;
      top: 6px;
      transition: all 0.5s ease;
      background: #fff;
      border: none;
      width: 40px;
      height: 30px;
      line-height: 30px;
      outline: none!important;
      &.show{
        right: 0px;
        left: inherit;
      }
      &:focus, &:active{
        outline: none;
        box-shadow: none!important;
      }
    }
    &.open{
      right: 10px;
    }

  }
  table {
    color: #333;
    background: #eee;
    width: 100%;
    //border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;
  }

  td, th {
    border: 1px solid transparent;
    height: 25px;

  }

  th {
    background: #DFDFDF;
    font-weight: normal;
  }

  td {
    background: #FAFAFA;
    text-align: center;
    input{
      border: 1px solid #ccc;
      background: none;
      height: inherit;
      margin: 0;
      padding: 0 3px;
      width: 100%;
      transition: all 0.3s;
      //&:active, &:focus{
      //  outline: none;
      //  color: #fff;
      //  background: #333;
      //}
    }
    .dms{
      input{
        border: 1px solid #ccc;
        width: 37px;
      }
    }
  }
  tr:nth-child(even) td { background: #F1F1F1; }
  tr:nth-child(odd) td { background: #FEFEFE; }

  .point-coordinate{
    width: 600px;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 3;
  }
}
.geographic-map, #geographic{
  //margin-top: 52px;
  width: 100%;
  height: 450px;

  position: relative;
  &.open-side{
    width: calc(100% - 320px);
  }

  #base-maps{
    z-index: 4;
    position: absolute;
    bottom: 0;
    left: 0;
    ul{
      li{
        list-style: none;
        display: inline-block;

        a{
          background: #fff;
          color: #0079c1;
          padding: 4px 8px;
          height: 22px;
          line-height: 22px;
          margin-right: 2px;
          &.active{
            background: #0079c1;
            color: #fff;
          }
        }
      }
    }
  }
  .leaflet-control-attribution{
    display: none;
  }
  .leaflet-bottom{
    margin-bottom: 30px;
  }
  .leaflet-bar{
    border: none !important;
  }



}
