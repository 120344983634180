.add-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);

    .add-tool {
        border-bottom: solid 1px #dedede;
        background: #f1f3f4;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 20px;

        &-actions {
            margin-left: auto;

            a {
                box-sizing: border-box;
                flex: 0 0 auto;
                height: 30px;
                line-height: 1;
                transition: 200ms box-shadow, 200ms color, 200ms background, 200ms fill;
                width: 24px;
                margin-left: 7px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                i {
                    color: #848f99;
                    font-weight: 500;
                    font-size: 17px;

                    &.ti-view-list-alt {
                        font-size: 16px;
                    }

                    &.ti-close {
                        font-size: 15px;
                    }
                }

                &:hover {
                    i {
                        color: darken(#848f99, 20%);
                    }
                }
            }
        }
    }

    .add-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 0px;

        .add-title {
            padding: 20px;
            font-size: 18px;
            text-transform: uppercase;
            width: calc(100% - 20px);

            .ivu-input {
                font-size: 18px;
                border: 0;
                padding-left: 0;
            }
        }

        .dataform-header {
            display: none;
        }

    }
}

.caret-container {
    position: absolute;
    top: 0;
    right: 0;

    .addable-caret {
        right: 24px;
        z-index: 0;
    }

    button {
        top: 4px;
        position: absolute;
        right: 4px;
    }
}
.clear-container {
    position: absolute;
    top: 0;
    right: 25px;
    z-index: 2;

    button {
        cursor: pointer;
        top: 4px;
        position: absolute;
        right: 4px;
        border:none;
    }
}
/*SUB FORM GRID*/
.subform-grid {
    .add-body{
        .ivu-form-item {
            margin-bottom: 24px !important;
        }
        .ivu-input {
            height: 32px !important;
            border: 1px solid #dcdee2 !important;
            border-radius: 4px !important;
        }
    }

}
