.lambda-checkbox-small
{
    font-size:10px;
    .ivu-checkbox-inner{
        height: 10px!important;
        width: 10px!important;
    }
    .ivu-checkbox-inner::after{
        left:3px;
        height: 6px;
    }
}
