.radio-with-thumb{
    label{
        height:auto!important;
        max-height: 100px!important;
        padding:0!important;
            img{
                height: 80px!important;
                padding: 5px 0!important;
            }
    }
    .ivu-radio-wrapper-checked {
    }
}
