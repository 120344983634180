.window-template {
    .dataform {
        background: #EAEEF2;
        border: solid 1px red;
        &-header {
            background: #ffffff;
            box-shadow: rgb(221 221 221) 0px 1px 6px;
            display: flex;
            align-items: center;
            padding: 0 0 0 15px;
            height: 50px !important;

            h3 {
                flex: 1;
            }
            &-actions {
                display: flex;
                align-items: center;
                height: 50px;
                overflow: hidden;

                a, .ivu-btn {
                    background: transparent !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    min-width: 50px;
                    border-left: solid 1px #dedede;
                    border-bottom: 0 !important;
                    border-top: 0 !important;
                    color: #666666 !important;
                    padding: 0 10px;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: 0 !important;
                    font-size: 14px !important;

                    span{
                        display: flex;
                        align-items: center;
                        i{
                            margin-right: 5px;
                        }
                    }

                    i {
                        color: #666666;
                        font-size: 18px !important;
                    }

                    &.print {
                        //width: 150px;
                        text-transform: uppercase;
                        font-size: 12px;

                        i {
                            margin-right: 5px;
                        }
                    }

                    &.cancel {
                        color: #ff4757;

                        i {
                            color: #ff4757;
                            margin-right: 5px;
                        }

                        &:hover {
                            i, span {
                                color: darken(#ff4757, 5%);
                            }
                        }
                    }

                    &.save {
                        color: #00cc66 !important;

                        i {
                            color: #00cc66 !important;
                            margin-right: 5px;
                        }

                        &:hover {
                            i, span {
                                color: darken(#00cc66, 5%);
                            }
                        }
                    }

                    &.nav-btn {
                        //width: 130px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 12px;
                        text-transform: uppercase;

                        i {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }

        &-body {
            background: #ffffff;
            //border: 1px solid rgba(132, 146, 189, .2);
            //border-radius: 5px;
            //box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
            //margin: 15px;
        }
    }
}

.modal-template {
    .dataform {
        &-header {
            background: #ffffff;
            border-bottom: solid 1px #dedede;
            display: flex;
            align-items: center;
            padding: 0 0 0 15px;
            height: 50px !important;

            h3 {
                flex: 1;
            }
            &-actions {
                display: flex;
                align-items: center;
                height: 50px;
                overflow: hidden;

                a, .ivu-btn {
                    background: transparent !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    min-width: 50px;
                    border-left: solid 1px #dedede;
                    border-bottom: 0 !important;
                    border-top: 0 !important;
                    color: #666666 !important;
                    padding: 0 10px;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: 0 !important;
                    font-size: 14px !important;

                    span{
                        display: flex;
                        align-items: center;
                        i{
                            margin-right: 5px;
                        }
                    }

                    i {
                        color: #666666;
                        font-size: 18px !important;
                    }

                    &.print {
                        //width: 150px;
                        text-transform: uppercase;
                        font-size: 12px;

                        i {
                            margin-right: 5px;
                        }
                    }

                    &.cancel {
                        color: #ff4757;

                        i {
                            color: #ff4757;
                            margin-right: 5px;
                        }

                        &:hover {
                            i, span {
                                color: darken(#ff4757, 5%);
                            }
                        }
                    }

                    &.save {
                        color: #00cc66 !important;

                        i {
                            color: #00cc66 !important;
                            margin-right: 5px;
                        }

                        &:hover {
                            i, span {
                                color: darken(#00cc66, 5%);
                            }
                        }
                    }

                    &.nav-btn {
                        //width: 130px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 12px;
                        text-transform: uppercase;

                        i {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }

        &-body {
            background: #ffffff;
            margin: 15px;
        }
    }
}

.step-form{
    .dataform-body{
        box-shadow: none !important;
        border: none !important;
    }
}

.dataform {
    background: #ffffff;
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: flex;

    .single-mode{
        width: 100%;
    }

    .ivu-form{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &-header {
        padding: 15px 20px;
        border-bottom: solid 1px #f1f3f4;
        background: #E7EBED;
        display: flex;

        h3 {
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
        }
    }

    &-body {
        padding: 15px 20px 10px;
        overflow-x: hidden;
        flex: 1;

        .fieldset {
            padding: 10px 8px;
            margin-bottom: 20px;
            border: 1px solid silver;

            legend {
                margin-top: -20px;
                background-color: white;
                padding: 0 10px;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }

        .form-divider {
            font-size: 12px;
            color: #515a6e;
            line-height: 1;
            font-weight: 600;
        }

        .ivu-divider-horizontal.ivu-divider-with-text-left:before {
            border-top: 1px solid #dcdee2 !important;
        }

        .ivu-divider-horizontal.ivu-divider-with-text-left:after {
            border-top: 1px solid #dcdee2 !important;
        }

        .ivu-col {
            padding-right: 5px;
            padding-left: 5px;
        }

        .ivu-tabs {
            padding-bottom: 40px !important;
        }
    }

    &-footer {
        margin-top: auto;
        padding: 10px 20px;
    }

    .extra-buttons {
        float: right;

        .ivu-btn {
            margin-left: 10px;
        }
    }
}
