.step-builder {
    display: flex;
    padding: 20px;

    &-body {
        flex: 1;
        height: calc(100vh - 60px);
        border-right: solid 1px #dedede;

        &-footer {
            padding: 0 20px 0 0;

            a {
                border: solid 1px #0aaa41;
                color: #0aaa41;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                padding: 6px 0;
                transition: all .25s;
                i{
                    margin-right: 6px;
                }

                &:hover {
                    border: solid 1px darken(#0aaa41, 5%);
                    color: darken(#0aaa41, 5%);
                }
            }
        }
    }

    &-config {
        width: 240px;
        min-width: 240px;
        padding: 0 20px;

        .pb-control-item{
            display: flex;
            padding-bottom: 8px;
            margin-bottom: 8px;
            label{
                flex: 1;
            }
        }
    }

    .step-item {
        &.active {
            background: lighten(#a4b0be, 20%);
            overflow: hidden;

            &:hover {
                background: lighten(#a4b0be, 20%);
            }
        }

        &:hover {
            background: #f7f8f9;
        }

        .fb-control {
            display: flex;
            align-items: center;

            .drag-handler {
                font-weight: 500;
                margin-right: 10px;
                font-size: 18px;
                height: 20px;
                width: 20px;
                color: #888888;
                padding: 0 15px;

                &:hover {
                    color: #2D8CED;
                    cursor: move;
                }
            }
        }

        .action {
            .delete-btn {
                color: #ff4757;
            }
        }
    }
}

.step-control-wrap{
    padding: 20px;
}

.step-form{
    background: #ffffff;
    border: 1px solid rgba(132, 146, 189, .2);
    border-radius: 5px;
    box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
    margin: 15px;
    height: calc(100vh - 80px);
    &-header{
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f3f4f5;
    }
    &-body{
        position: relative;
        height: calc(100vh - 140px);
        .dataform{
            background: #ffffff;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            overflow-y: auto;
            &-header, &-footer{
                display: none !important;
            }
        }
    }
}
