.info-modal {
    .ivu-drawer-wrap {
        z-index: 10000;
    }

    .ivu-drawer-body {
        padding: 0;

    }

    .ivu-drawer-close {
        background: #fff;
        border: 1px solid #ccc;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}
