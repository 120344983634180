.item-more-options {
    display: none !important;
    padding: 0 !important;
    border: solid 1px #CCCCCC;
    border-top: solid 3px #dedede;
    &.active {
        display: block !important;
    }
    .expand {
        background: #E0E4E9;
        padding: 0;
        margin-bottom: 2px;
        .expand-tab {
            height: auto;
            overflow: visible;
            .ivu-tabs-bar {
                background: #fafbfc;
                .ivu-tabs-nav-wrap {
                    margin-bottom: 0;
                    height: 30px;
                }

                .ivu-tabs-tab {
                    background: none;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 500;
                }

                .ivu-tabs-tab-active:before {
                    display: none;
                }

                .ivu-tabs-tab-active:after {
                    content: '';
                    display: block;
                    height: 2px;
                    background: #3399ff;
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                    right: 0;
                }
            }

            .ivu-tabs-content {
                height: auto !important;
                border: none !important;
                padding-bottom: 30px;
                .ivu-row-flex {
                    .ivu-col {
                        flex-direction: column;
                        //border-bottom: solid 1px #dedede;
                        .ivu-select-multiple .ivu-select-item-selected:after {
                            margin-left: auto;
                        }
                        .title {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            padding-top: 20px;
                            padding-bottom: 10px;
                            h3 {
                                font-size: 11px;
                                text-transform: uppercase;
                                font-weight: 500;
                                margin: 0 10px 0 20px;
                                color: darken(#a4b0be, 15%);
                                position: relative;
                                &::after {
                                    position: absolute;
                                    content: "";
                                    top: 10px;
                                    display: block;
                                    height: 3px;
                                    width: 100%;
                                }
                            }
                        }
                        &:last-child {
                            border-right: 0;
                            //width: 450px !important;
                        }
                        .localSelectOptions{
                            width: 97%;
                            padding-top: 20px;
                            padding-bottom: 10px;
                        }
                        > ul {
                            list-style: none;
                            width: 100%;
                            > li {
                                width: 100%;
                                padding: 5px 20px;
                            }
                        }

                        &.custom-col {
                            border-right: solid 1px #dedede;
                            li {
                                display: flex;
                                flex-direction: column;
                                > label {
                                    font-size: 12px;
                                    display: block;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        &.cond-col {
                            border-right: solid 1px #dedede;
                            li {
                                display: flex;
                                flex-direction: column;
                                > label {
                                    font-size: 12px;
                                    display: block;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        &.rule-col {
                            display: block;
                            border-right: solid 1px #dedede;
                            .rule-control {
                                width: 100%;
                                padding: 5px 20px 10px;
                            }
                            > ul {
                                > li {
                                    display: flex;
                                    align-items: center;
                                    > label {
                                        font-size: 12px;
                                        width: 90px;
                                    }
                                    > span {
                                        flex: 1;
                                    }
                                    .ivu-input {
                                        color: #555555;
                                    }
                                }
                            }
                        }
                        &.rel-col {
                            > ul {
                                > li {
                                    display: flex;
                                    align-items: center;
                                    > label {
                                        font-size: 12px;
                                        min-width: 140px !important;
                                        max-width: 140px !important;
                                    }
                                    .ivu-select {
                                        flex: 1;
                                    }
                                    .ivu-radio-group {
                                        flex: 1;
                                    }
                                }
                            }
                        }
                        .conditions {
                            .ivu-select {
                                margin-right: 10px;
                            }
                            .c-val {
                                width: 100px;
                            }
                        }
                        .rule-control{
                            width: 100%;
                            padding: 25px 0 0 18px;
                        }
                    }
                }

            }
        }
    }
    .trigger-example {
        font-size: 9px;
        background: #130f0d;
        padding: 5px;
        color: #fff;
    }
}
