.trigger-wrapper {
    padding: 20px;

    .trigger-add-btn-form
    {
        border: 1px dashed #999999;
        padding: 20px;
        margin-top: 10px;
        border-radius: 5px;
    }
    .trigger-add-btn-table{
        padding-top: 30px;
    }

    >table {
        td {
            padding: 5px 10px;
            &:first-child {
                width: 150px
            }

            &:last-child {
                width: 350px;
            }

            label {
                font-size: 13px;
            }
        }
    }
}
